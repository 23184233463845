<template>
  <v-card
    color="element"
    class="mx-auto py-5 px-5"
    max-width="600"
  >
    <h3 class="font-weight-medium">{{$t(`onboarding.investmentSurvey.products.${productType}`)}}</h3>
    <p>
      {{ $t('onboarding.investmentSurvey.views.products.knowledgeQuestion',
          { product: $t(`onboarding.investmentSurvey.products.${productType}`) }
      )}}
    </p>
    <br>
    <v-form
      @submit.prevent="submit"
    >
      <!-- KNOWLEDGE -->
      <v-btn-toggle
        :class="['mt-5', 'mb-10', $vuetify.theme.dark ? 'knowledge-dark' : 'knowledge-light']"
        v-model="data.investmentSurvey.investmentExperience[productCategory][stepIndex].knowledge"
        rounded
      >
        <v-btn
          v-for="answer in knowledge"
          :key="answer.value"
          :value="answer.value"
          :id="'knowledge-'+answer.value"
          :class="`${data.investmentSurvey.investmentExperience[productCategory][stepIndex].knowledge === answer
            ? 'active'
            : ''}`
          "
        >
          {{ answer.text }}
        </v-btn>
      </v-btn-toggle>

      <!-- EXPERIENCE -->
      <div>
        <p>
          {{ $t('onboarding.investmentSurvey.views.products.experienceQuestion',
              { product: $t(`onboarding.investmentSurvey.products.${productType}`) }
          )}}
        </p>
        <v-slider
          v-model="productExperience"
          :tick-labels="experienceOptions"
          :max="2"
          ticks="always"
        ></v-slider>
      </div>
    </v-form>
    <!-- <SkipInvestmentSurvey /> -->
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import lazyLoad from '@/utils/lazyLoad';
import clone from '@/utils/clone';
import { mapFrontendValueToDbEnum, mapDbEnumToFrontendValue } from '@/mappings/onboarding/investmentSurvey';

export default {
  name: 'InvestmentSurveyProducts',
  // components: {
  //   SkipInvestmentSurvey: lazyLoad('components/Onboarding/SkipInvestmentSurvey'),
  // },
  data() {
    return {
      data: clone(this.$store.state.user.data),
      categoryIndex: 0,
      stepIndex: 0,
      productExperience: 0,
    };
  },
  created() {
    if (this.productExperienceDone) {
      this.categoryIndex = 2;
      this.stepIndex = 1;
    }
    if (this.selectedKnowledge) {
      this.setFormValidity(true);
    } else {
      this.setFormValidity(false);
    }
    this.checkProductExperience(this.data.investmentSurvey.investmentExperience[this.productCategory][this.stepIndex].experience);
  },
  computed: {
    ...mapGetters('onboarding', [
      'knowledge',
      'experiences',
      'productExperienceDone',
    ]),
    productKnowledge() {
      return this.data.investmentSurvey.investmentExperience[this.productCategory][this.stepIndex].knowledge;
    },
    selectedKnowledge() {
      return new Set([false, true]).has(this.productKnowledge);
    },
    experienceOptions() {
      return this.experiences.map((e) => e.text);
    },
    productCategories() {
      return Object.keys(this.data.investmentSurvey.investmentExperience);
    },
    productCategory() {
      return this.productCategories[this.categoryIndex];
    },
    productType() {
      return this.data.investmentSurvey.investmentExperience[this.productCategory][this.stepIndex].type;
    },
  },
  watch: {
    selectedKnowledge(v) {
      if (v) {
        this.setFormValidity(true);
      } else {
        this.setFormValidity(false);
      }
    },
    productExperience(v) {
      this.data.investmentSurvey.investmentExperience[this.productCategory][this.stepIndex]
        .experience = mapFrontendValueToDbEnum.rangeOfExperience[v];
    },
  },
  methods: {
    ...mapActions('onboarding', [
      'setFormValidity',
      'setProductExperienceDone',
    ]),
    ...mapActions('user', [
      'updateUser',
      'setSubStage',
      'updateOnboarding',
      'previousStep',
    ]),
    checkProductExperience(product) {
      this.productExperience = mapDbEnumToFrontendValue.rangeOfExperience[product];
    },
    previous(step) {
      if (this.categoryIndex > 0 || this.stepIndex > 0) {
        if (this.stepIndex > 0) {
          this.stepIndex -= 1;
        } else {
          this.stepIndex = 1;
          this.categoryIndex -= 1;
        }
        this.checkProductExperience(
          this.data.investmentSurvey.investmentExperience[this.productCategory][this.stepIndex].experience,
        );
      } else {
        this.setProductExperienceDone(false);
        this.setSubStage(step);
        this.previousStep();
      }
    },
    async submit() {
      if (this.categoryIndex !== 2 || this.stepIndex !== 1) {
        if (this.stepIndex < 1) {
          this.stepIndex += 1;
        } else {
          this.stepIndex = 0;
          this.categoryIndex += 1;
        }
        if (this.productKnowledge === null) {
          this.productExperience = 0;
          this.setFormValidity(false);
        } else {
          this.checkProductExperience(
            this.data.investmentSurvey.investmentExperience[this.productCategory][this.stepIndex].experience,
          );
        }
      } else {
        try {
          const input = {
            investor: {
              investmentSurvey: {
                products: this.data.investmentSurvey.investmentExperience,
              },
            },
            mainStage: 'investmentSurvey',
            subStage: 'products',
          };
          await this.updateOnboarding({
            input,
            newData: this.data,
          });
          this.setProductExperienceDone(true);
          this.setSubStage('services');
        } catch (error) {
          this.$notify.error(error.message);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.knowledge-light {
  border: 2px solid #CEB390;
  text-transform: uppercase;
  display: flex;
  .v-btn {
    width: 50%;
    background: #FCFCFC !important;
    font-weight: 900;
  }
  .active {
    color: #000 !important;
    background: #E6C8A0 !important;
  }
}
.knowledge-dark {
  border: 2px solid #CEB390;
  text-transform: uppercase;
  display: flex;
  .v-btn {
    width: 50%;
    background: #192128 !important;
    font-weight: 900;
  }
  .active {
    color: #000 !important;
    background: #E6C8A0 !important;
  }
}
</style>
